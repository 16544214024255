import React, { useEffect } from "react";
import Form1 from "../../components/forms/form1";
const Signin = () => {
  return (
    <>
      <Form1 />
    </>
  );
};

export default Signin;
