import React from 'react'
import Form2 from '../../components/forms/form2'
const Signup = () => {
  return (
    <>
        <Form2 />
    </>
  )
}

export default Signup