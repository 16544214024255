import React from 'react'
import Dashy from '../../components/dashy/dashy'
const Dashboard = () => {
  return (
    <>
      <div className='bg-gray-100 h-screen'>
        <Dashy />
      </div>
    </>
  )
}

export default Dashboard