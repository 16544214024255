import React from 'react'
import Table from '../../components/Table/table'
const History = () => {
  return (
    <>
      <div className='bg-gray-100 h-screen'>
        <Table />
      </div>
    </>
  )
}

export default History